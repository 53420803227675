import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'; // Import BrowserRouter and Routes
import recommendedLogo from './recommended.svg';
import googleLogo from './google.png';
import './App.css';
import './firebase.js';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, onValue, set, push, update, onDisconnect, serverTimestamp, orderByChild, limitToLast, query } from 'firebase/database';
import AboutUs from './components/AboutUs.js'; // Import the PrivacyPolicy component
import PrivacyPolicy from './components/PrivacyPolicy.js'; // Import the PrivacyPolicy component
import ShippingPolicy from './components/ShippingPolicy.js'; // Import the PrivacyPolicy component
import ContactUs from './components/ContactUs.js'; // Import the PrivacyPolicy component
import RefundPolicy from './components/RefundPolicy.js'; // Import the PrivacyPolicy component
import TermsAndConditions from './components/TermsAndConditions.js'; // Import the PrivacyPolicy component
import Pricing from './components/Pricing.js'; // Import the PrivacyPolicy component
import Youtube from './components/Youtube.js'; // Import the PrivacyPolicy component
import WebRTC from './components/WebRTC.js'; // Import the PrivacyPolicy component
import User from './components/User.js'; // Import the PrivacyPolicy component
import SearchC from './components/SearchC.js';
import {Dropdown, Space, Menu} from 'antd';
import { DownOutlined, AppstoreOutlined, MailOutlined, SettingOutlined  } from '@ant-design/icons';
import type { MenuProps } from 'antd';
type MenuItem = Required<MenuProps>['items'][number];

const auth = getAuth();
const database = getDatabase();

//const myRef = ref(database, 'users/mohit');
//console.log(myRef)
//const lastOnlineRef = ref(database, "users/mohit/lastOnline");
const connectedRef = ref(database, ".info/connected");
onValue(connectedRef, (snap) => {
  if (snap.val() === true) {
    // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)

    //update(myRef, {cameOnline: serverTimestamp()});

    //console.log(myConnectionsRef, con);

    // When I disconnect, remove this device
    // const myConnectionsRef = ref(database, 'users/mohit/connections');
    // const con = push(myConnectionsRef);
    // onDisconnect(con).remove();
 
    // Add this device to my connections list
    // this value could contain info about the device or a timestamp too
    //set(con, true);

    // When I disconnect, update the last time I was seen online
    //onDisconnect(lastOnlineRef).set(serverTimestamp());
  }
});

const provider = new GoogleAuthProvider();

function convertToUsername(name) {
  // Convert the name to lowercase
  const lowercaseName = name.toLowerCase();

  // Replace spaces with dashes
  const username = lowercaseName.replace(/\s+/g, '-');

  return username;
}

const itemsProps: MenuProps['items'] = [
  {
    key: '1',
    label: (
      <Link to={`/me`} style={{ textDecoration: 'none', color: 'inherit' }}>Profile</Link>
    ),
  },
  {
    key: '3',
    danger: true,
    label: 'Sign Out',
  },
];

const items: MenuItem[] = [
  {
    label: (<Link to={`/`} style={{ textDecoration: 'none', color: 'inherit' }}>Home</Link>),
    key: 'mail',
    icon: <MailOutlined />,
  },
  {
    label: 'User',
    key: 'SubMenu',
    icon: <SettingOutlined />,
    children: [
      {
        type: 'group',
        label: 'Item 1',
        children: [
          { label: 'Option 1', key: 'setting:1' },
          { label: 'Option 2', key: 'setting:2' },
        ],
      },
      {
        type: 'group',
        label: 'Item 2',
        children: [
          { label: 'Option 3', key: 'setting:3' },
          { label: 'Option 4', key: 'setting:4' },
        ],
      },
    ],
  },
  {
    key: 'alipay',
    label: (
      <Link to={`/user/me`} style={{ textDecoration: 'none', color: 'inherit' }}>Profile</Link>
    ),
  },
];

function App() {
  const [user, setUser] = useState(null);
  const [showInput, setShowInput] = useState(true);
  const [videoId, setVideoId] = useState(null);
  const [videoIds, setVideoIds] = useState([]);
  const [nextVideoId, setNextVideoId] = useState(null);
  const [index, setIndex] = useState(0);
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const [current, setCurrent] = useState('mail');

  useEffect(() => {
    const videoRef = ref(database, 'videos');
    const orderedQuery = query(videoRef, orderByChild('count'), limitToLast(3)); // Limiting to last 10 results
    
    onValue(orderedQuery, (snapshot) => {
      const videoIds = [];
      snapshot.forEach((childSnapshot) => {
        console.log(childSnapshot.key);
        videoIds.push(childSnapshot.key);
      });
      // Set the videoIds state to the array of video IDs
      setVideoIds(videoIds);
      
      // Set the videoId state to the first video ID from the array
      if (videoIds.length > 0) {
        setVideoId(videoIds[0]);
      }
    });
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });
  
    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      unsubscribe();
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const videoIdsRef = useRef(videoIds);
  videoIdsRef.current = videoIds; // Update the ref when videoIds changes
  const handleMouseDown = () => {
    console.log(videoId, videoIdsRef.current); // Use videoIdsRef.current

    let videoIdsCurrent = videoIdsRef.current;
    console.log('down');
    console.log(videoId, videoIdsCurrent);
    if (!videoIdsCurrent || !videoId) return;
  
    const currentIndex = videoIdsCurrent.indexOf(videoId);
    if (currentIndex !== -1 && currentIndex < videoIdsCurrent.length - 1) {
      const nextIndex = currentIndex + 1;
      setVideoId(videoIdsCurrent[nextIndex]);
    }
  };

  const handleClickOutside = (event) => {
    let outsideHeader = inputRef.current && !inputRef.current.contains(event.target);
    let outsideFooter = inputRef2.current && !inputRef2.current.contains(event.target);
    if (outsideHeader && outsideFooter) {
      setShowInput(false);
    } else setShowInput(true);
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result)
        const user = {
          uid: result.user.uid,
          displayName: result.user.displayName,
          email: result.user.email,
          photoURL: result.user.photoURL,
        }
        
        const myRef = ref(database, 'users/' + convertToUsername(user.displayName) + '/count');
        set(myRef, 1);

        console.log(user); 
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
      })
      .catch((error) => {
        console.log(error)
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };

  console.log(user)

  const signOutUser = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const toggleInput = () => {
    setShowInput(!showInput);
  };

  const onClick: MenuProps['onClick'] = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  return (
    <Router>
      <div className="App">
        <Menu style={{marginTop: 50, marginBottom: -50, marginLeft: 600}} onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
        {showInput && (
          <div style={{ background: '#282c34', width: '100vw', minHeight: '7vh', position: 'absolute', top: 0}} ref={inputRef}>
            <div>
              <a href="/" style={{alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width: '5vh', height: '5vh', position: 'absolute', left: 0, paddingLeft: '2vh', paddingTop: '1vh', marginTop: 'auto'}} src={recommendedLogo} className="App-logo" alt="logo" />
                <p className="sil" style={{color: 'white', fontSize: '2.5vh', position: 'absolute', left: '10vh', margin: 0, paddingTop: '1.5vh', marginLeft: '-2vh'}}>Recommenderz</p>
              </a>
            </div>
            <SearchC />
            {user ? (
            <div style={{marginRight: '2vh', position: 'absolute', right: 0, cursor: 'pointer', top: 5}}>
              <p style={{fontWeight: 200, fontFamily: 'monospace', color: 'white', fontSize: 15}} onClick={signOutUser}>{user.displayName}</p>
             </div>
            ) : (
            <div style={{ position: 'absolute', right: 0, top: 0, height: '7vh'}}>
              <button onClick={signInWithGoogle} style={{ background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ccc', borderRadius: '5px', cursor: 'pointer', margin: '1vh'  }}>
                <img src={googleLogo} alt="Google logo" style={{ width: '2.5vh', margin: '5px' }} />
                <p style={{fontFamily: 'monospace', margin: 0, fontSize: '1.5vh'}}>Login with Google</p>
              </button>
            </div>
            )}
          </div>
        )}      
        <Routes>
        <Route path="/static/about-us" element={<AboutUs />} />
        <Route path="/static/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/static/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/static/refund-policy" element={<RefundPolicy />} />
          <Route path="/static/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/static/pricing" element={<Pricing />} />
          <Route path="/static/contact-us" element={<ContactUs />} />
          <Route path="/video/:videoId" key="video" element={<Youtube />} />
          <Route path="/mirror" element={<WebRTC />} />
          <Route path="/user/:userId" element={<User />} />
          <Route path="/" key={videoId} element={
            videoIds.map((videoId) => <Youtube videoId={videoId}
          />)} />
          {/* Add routes for other static content pages */}
        </Routes>
        {true && (
          <div style={{ background: '#282c34', width: '100vw', height: '7vh', padding: '10px', position: 'relative', bottom: 0}} ref={inputRef2}>
            <div>
            <a style={{padding: 5, color: 'white', fontFamily: 'monospace'}} href="/static/about-us">About Us</a>
            <a style={{padding: 5, color: 'white', fontFamily: 'monospace'}} href="/static/pricing">Pricing</a>
            <a style={{padding: 5, color: 'white', fontFamily: 'monospace'}} href="/static/privacy-policy">Privacy Policy</a>
            <a style={{padding: 5, color: 'white', fontFamily: 'monospace'}} href="/static/shipping-policy">Shipping Policy</a>
            <a style={{padding: 5, color: 'white', fontFamily: 'monospace'}} href="/static/refund-policy">Refund Policy</a>
              <a style={{padding: 5, color: 'white', fontFamily: 'monospace'}} href="/static/terms-and-conditions">Terms and Conditions</a>
              <a style={{padding: 5, color: 'white', fontFamily: 'monospace'}} href="/static/contact-us">Contact Us</a>

            </div>
          </div>
        )}
</div>
    </Router>
  );
}

export default App;
