import React from 'react';

function ShippingPolicy() {
  return (
    <div style={{ margin: '20px', fontWeight: 200, textAlign: 'left', marginTop: '100px', minHeight: '100vh' }}>
      <h1>Shipping Policy</h1>
      <p>
        At Recommenderz, we provide digital products that are delivered electronically. Once your payment has been successfully processed, the product will be sent to the email address you provided during checkout. Please ensure that the email address you provide is accurate and accessible.
      </p>

      <h2>Delivery Time:</h2>
      <p>
        After the payment confirmation, you will receive an email containing the digital product within a few minutes. In rare cases, it may take up to 24 hours depending on factors such as email service delays or system issues.
      </p>

      <h2>Non-Delivery Issues:</h2>
      <p>
        If you do not receive the email with the digital product within the expected timeframe, please:
      </p>
      <ul>
        <li>Check your spam or junk folder to ensure the email was not filtered there.</li>
        <li>Verify that the email address provided during purchase was correct.</li>
        <li>Contact our support team at contact@rcommndr.com for assistance if the email was not received.</li>
      </ul>

    </div>
  );
}

export default ShippingPolicy;
