import React, { useState } from 'react';
import { Button, Form, Input, message } from 'antd';

const { TextArea } = Input;

function ContactUs() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);

    // Mock function to simulate email sending
    setTimeout(() => {
      console.log('Email sent:', values);
      setLoading(false);
      message.success('Your message has been sent successfully!');
      form.resetFields(); // Reset form after successful submission
    }, 2000);
  };

  const onFinishFailed = (errorInfo) => {
    message.error('Please fill out the form correctly.');
    console.error('Failed:', errorInfo);
  };

  return (
    <div style={{ margin: '20px', fontWeight: 200, textAlign: 'left', marginTop: '100px', minHeight: '100vh' }}>
      <h1>Contact Us</h1>
      <Form
        form={form}
        name="contactForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        style={{ maxWidth: '600px' }}
      >
        <Form.Item
          label="Your Name"
          name="name"
          rules={[{ required: true, message: 'Please enter your name!' }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>

        <Form.Item
          label="Your Email"
          name="email"
          rules={[
            { required: true, message: 'Please enter your email!' },
            { type: 'email', message: 'Please enter a valid email!' },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>

        <Form.Item
          label="Subject"
          name="subject"
          rules={[{ required: true, message: 'Please enter the subject!' }]}
        >
          <Input placeholder="Enter subject" />
        </Form.Item>

        <Form.Item
          label="Message"
          name="message"
          rules={[{ required: true, message: 'Please enter your message!' }]}
        >
          <TextArea rows={4} placeholder="Enter your message" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ContactUs;
