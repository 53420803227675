import React from 'react';

function AboutUs() {
  return (
    <div style={{ margin: '20px', fontWeight: 200, textAlign: 'left', marginTop: '100px', minHeight: '100vh' }}>
      <h1>About Us</h1>
      <p>
        Welcome to <strong>Recommenderz</strong>, your go-to platform for personalized video recommendations! Our mission is to bring the best content creators and viewers together through curated, community-driven video suggestions.
      </p>

      <h2>Our Vision</h2>
      <p>
        At Recommenderz, we believe that everyone has a unique taste, and discovering new content shouldn't be limited by algorithms alone. We empower users to recommend their favorite YouTube videos to their followers, creating a more human and personal connection between creators and their audiences. Our platform bridges the gap between great content and viewers by making it easier to find videos that matter to you.
      </p>

      <h2>Why We Started</h2>
      <p>
        The explosion of video content online can be overwhelming, making it hard to find what really resonates with you. Our founders saw an opportunity to create a space where video recommendations come not just from an algorithm, but from real people who share your interests. With <strong>Recommenderz</strong>, you get trusted suggestions from the people and creators you follow.
      </p>

      <h2>What We Offer</h2>
      <ul>
        <li>A platform for creators to share and recommend their favorite content with followers.</li>
        <li>Easy integration with YouTube, making it seamless to discover and share new videos.</li>
        <li>A simple, swipeable card interface designed for mobile-first users.</li>
        <li>Real-time updates and notifications whenever someone you follow shares a new recommendation.</li>
      </ul>

      <h2>Our Team</h2>
      <p>
        We're a small team of passionate developers, designers, and content creators who love great video content. Based in India, we work together to build a platform that serves our community of video lovers and creators.
      </p>

      <h2>Join Us!</h2>
      <p>
        Whether you're a content creator looking to engage your audience or a viewer in search of the next great video, <strong>Recommenderz</strong> is the place for you. Join us and become part of a growing community that values genuine recommendations and personalized experiences.
      </p>

      <p>
        If you have any questions or want to learn more, feel free to <a href="/contact">Contact Us</a>.
      </p>
    </div>
  );
}

export default AboutUs;
